<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  required: {
    type: Boolean,
    required: false,
  },
  successMessage: {
    type: String,
    default: '',
  },
  customError: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
  },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<template>
  <div
    class="select"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="props.label" :for="props.id"
      >{{ props.label }}<span v-if="props.required">*</span></label
    >
    <div class="select__wrap">
      <select
        :name="props.name"
        :id="props.id"
        :value="inputValue"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        @input="handleChange"
        @blur="handleBlur"
        class="select__dropdown"
      >
        <option
          v-for="(option, idx) in props.options"
          :key="idx"
          :value="option.option"
          class="select__options"
        >
        {{ option.option }}
        </option>
      </select>
    </div>
    <p class="select__help" v-show="errorMessage || meta.valid">
        {{ errorMessage }}
      </p>
  </div>
</template>

<style scoped>
.select.has-error select {
  color: var(--red);
  border: 1px solid var(--red);
}

.select.has-error select:focus {
  border-color: var(--red);
}

.select.has-error .help-message {
  color: var(--red);
}

.select__help {
  margin-top: var(--space-xxs);
  font-size: 12px;
  color: var(--red);
}

</style>
