<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import { number } from 'yup';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  required: {
    type: Boolean,
    required: false
  },
  rows: {
    type: Number,
    required: false
  },
  successMessage: {
    type: String,
    default: '',
  },
  customError: {
    type: String,
    required: false
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<template>
  <div
    class="text__area"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="props.label" :for="props.id">{{ props.label }}<span v-if="props.required">*</span></label>
    <textarea
      :name="props.name"
      :id="props.id"
      :type="props.type"
      :value="inputValue"
      :rows="props.rows"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      @input="handleChange"
      @blur="handleBlur"
    ></textarea>

    <p class="text__area__help" v-show="errorMessage || meta.valid">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped>
.text__area.has-error textarea {
  color: var(--red);
  border: 1px solid var(--red);
}

.text__area.has-error textarea:focus {
  border-color: var(--red);
}

.text__area.has-error .help-message {
  color: var(--red);
}

.text__area__help {
  margin-top: var(--space-xxs);
  font-size: 12px;
  color: var(--red);
}
</style>
